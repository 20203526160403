import AWS from 'aws-sdk/dist/aws-sdk-react-native';

export const back = (history) => {
   history.goBack();
};

export const inIframe  = () => {
  try {
      return window.self !== window.top;
  } catch (e) {
      return true;
  }
}

export const close = (history, settings) => {

  window.top.postMessage('CLOSE', '*');

  /*
  const path = window.location.pathname;
  const lastItem = path.substring(path.lastIndexOf('/') + 1)

  if(lastItem !== "verificationprocessing"){
    const close_reason_active = settings.close_reason_active;
    if(close_reason_active){
      history.push(`${process.env.PUBLIC_URL}/close`);
    }else{
      window.top.postMessage('CLOSE', '*');
    }
  }else{
    window.top.postMessage('CLOSE', '*');
  }
  */

};


export const onEmailLink = () => {
   window.top.postMessage('EMAIL', '*');
};

export const onQr = () => {
   window.top.postMessage('QR', '*');
};

export const nextPath = (history, path) => {
   history.push(path);
};

export const getDataForEmail = (request_id, state) => {

  const post = {request_id: request_id};

  console.log('Using environment variables:', process.env);

  fetch(process.env.REACT_APP_BACKEND_URL + "/v3/stable/api/frontend/getUrl",
{
    method: "POST",
    body: JSON.stringify(post)
})
.then(function(res){ return res.json(); })
.then(function(data){

  if(data.already_done){
    state({
      isLoading: false,
      error: true,
      errorMessage: data.data.settings.text.errors.alreadyDoneTitle,
      errorDescription: data.data.settings.text.errors.alreadyDoneSubtitle,
    });
  }else if(data.data !== false){
    state({
      isLoading: false,
      error: false,
      query: data.data,
      settings: data.data.settings
    })
    styleUpdate(data.data.settings);
  }else{
    state({
      isLoading: false,
      error: true,
      errorMessage: data.data.settings.text.errors.noAccessTitle,
      errorDescription: data.data.settings.text.errors.noAccessSubtitle,
    });
  }
})
.catch(function(e) {
  state({isLoading: false, error: true, errorMessage: "Something Went Wrong",errorDescription: "This is a system error. Please contact customer service."});
})

}

export const registerforRegenerate = (post, state, device, _callback) => {

  post['device'] = device;

  if(post.email){
    post['email'] = post.email.replace(/\s/g, "+");
  }

  fetch(process.env.REACT_APP_BACKEND_URL + "/v3/stable/api/frontend/register",
{
    method: "POST",
    body: JSON.stringify(post)
})
.then(function(res){ return res.json(); })
.then(function(data){
  if(data.action === "ERROR"){
    state({
      isLoading: false,
      error: true,
      errorMessage: data.errorMessage,
      errorDescription: data.errorDescription
    });
    window.top.postMessage(data.detail, '*');
  }else{
    _callback();
    state({
      isLoading: false,
      settings: data,
      error: false,
      requestID: data.request_id
    });

    styleUpdate(data);
  }
})
.catch(function() { state({isLoading: false, error: true, errorMessage: "Something Went Wrong",errorDescription: "This is a system error. Please contact customer service."}); })

}

export const register = (post, state, device) => {

  post['device'] = device;

  if(post.email){
    post['email'] = post.email.replace(/\s/g, "+");
  }

  fetch(process.env.REACT_APP_BACKEND_URL + "/v3/stable/api/frontend/register",
{
    method: "POST",
    body: JSON.stringify(post)
})
.then(function(res){ return res.json(); })
.then(function(data){
  if(data.action === "ERROR"){
    state({
      isLoading: false,
      error: true,
      errorMessage: data.errorMessage,
      errorDescription: data.errorDescription
    });
    window.top.postMessage(data.detail, '*');
  }else{
    state({
      isLoading: false,
      settings: data,
      error: false,
    });

    styleUpdate(data);
  }
})
.catch(function() { state({isLoading: false, error: true, errorMessage: "Something Went Wrong",errorDescription: "This is a system error. Please contact customer service."}); })
}

export const nextUpload = (history, path, state, type) => {
     state.file = null;
     state.text = "Upload " + capitalizeFirstLetter(type);
     state.UploadButtonClass = "veratad-primary";
     state.loadingFile = false;
     state.ext = null;
     state.target = type;
     state.fileUploaded = null;
     history.push(path);
};

export const sendEmail = ( data, settings, setState, history ) => {
  data.settings = settings;

  fetch( process.env.REACT_APP_BACKEND_URL + "/v3/stable/api/frontend/sendEmail.php",
{
    method: "POST",
    body: JSON.stringify(data)
})
.then(function(res){ return res.json(); })
.then(function(data){
  setState({
    isLoading: false,
    text: data.text,
    subtext: data.subtext
  })
  if(data.result === "success"){
    setState({
      title: "Email Sent"
    })
  }else{
    setState({
      title: "Email Failed to Send",
      error: true
    })
  }
})
.catch(function() { history.replace(process.env.PUBLIC_URL + '/error'); })
}

export const registerQr = ( data, settings, setState, history ) => {
  data.settings = settings;

  fetch( process.env.REACT_APP_BACKEND_URL + "/v3/stable/api/frontend/storeQr.php",
{
    method: "POST",
    body: JSON.stringify(data)
})
.then(function(res){ return res.json(); })
.then(function(data){



})
.catch(function() { history.replace(process.env.PUBLIC_URL + '/error'); })
}

export const verifyPost = (data, settings, history ) => {

  history.push('./verificationprocessing');
  const request_id = settings.request_id;
  data.request_id = request_id;
  data.country = settings.country;
  if(!("front_ext" in data)){
    data.front_ext = "jpeg";
    data.back_ext = "jpeg";
  }
  fetch( process.env.REACT_APP_BACKEND_URL + "/v3/stable/api/frontend/process.php",
{
    method: "POST",
    body: JSON.stringify(data)
})
.then(function(res){ return res.json(); })
.then(function(data){ return true })
.catch(function() { history.replace(process.env.PUBLIC_URL + '/error'); })
}

export const verifyPostSelfie = (data, settings, history ) => {

  history.push('./verificationprocessing');
  const request_id = settings.request_id;
  data.request_id = request_id;
  data.template = settings.template;
  if(!("front_ext" in data)){
    data.front_ext = "jpeg";
    data.back_ext = "jpeg";
  }
  fetch( process.env.REACT_APP_BACKEND_URL + "/v3/stable/api/frontend/selfie/process.php",
{
    method: "POST",
    body: JSON.stringify(data)
})
.then(function(res){ return res.json(); })
.then(function(data){ })
.catch(function() { history.replace(process.env.PUBLIC_URL + '/error'); })
}

export const statusCheck = (setState, settings, data, history, selfie) => {

  const post = {token: settings.token, request_id: settings.request_id, email: settings.email, data: data};
  fetch( process.env.REACT_APP_BACKEND_URL + "/v3/stable/api/frontend/statusCheck",
{
    method: "POST",
    body: JSON.stringify(post)
})
.then(function(res){ return res.json(); })
.then(function(data){
  if(data.status === "NEW"){
    setState({isLoading: true, action: null})
  }else{
    if(data.status === "PASS"){
      window.top.postMessage('PASS', '*');
    }else{

      if(settings.isManualSynch){
        history.replace(process.env.PUBLIC_URL + '/manualwait');
      }else{
        window.top.postMessage('FAIL', '*');
      }

    }
    setState({isLoading: false, action: data.status, icon: data.icon, header: data.title, sub: data.subtitle, enhanced_complete: data.enhanced_complete, enhanced_template: data.enhanced_next_document, isTryAgain: data.isTryAgain})
  }

})
.catch(function() { history.replace(process.env.PUBLIC_URL + '/error'); })

};

export const statusCheckManualSynch = (setState, settings, data, history, selfie) => {

  const post = {token: settings.token, request_id: settings.request_id, email: settings.email, data: data};
  fetch( process.env.REACT_APP_BACKEND_URL + "/v3/stable/api/frontend/statusCheck",
{
    method: "POST",
    body: JSON.stringify(post)
})
.then(function(res){ return res.json(); })
.then(function(data){
  if(data.status === "PENDING"){
    setState({isLoading: true, action: null})
  }else{
    if(data.status === "PASS"){
      window.top.postMessage('PASS', '*');
    }else{
        window.top.postMessage('FAIL', '*');
    }
    setState({isLoading: false, action: data.status, icon: data.icon, header: data.title, sub: data.subtitle, enhanced_complete: data.enhanced_complete, enhanced_template: data.enhanced_next_document, isTryAgain: data.isTryAgain})
  }

})
.catch(function() { history.replace(process.env.PUBLIC_URL + '/error'); })

};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}


export const S3Upload = (id, setState, files, request_id, _callback) => {

  var albumBucketName = "dcamsv3";
  var bucketRegion = "us-east-2";
  var IdentityPoolId = "us-east-2:d6381cdb-1612-4dd1-ad4a-c8b28de1bd8b";
  var albumName = "upload";

  AWS.config.update({
    region: bucketRegion,
    credentials: new AWS.CognitoIdentityCredentials({
      IdentityPoolId: IdentityPoolId
    })
  });

  var file = files;
  var fileInitalName = file.name;
  var name = "dl_" + id;
  var fileName;
  if(fileInitalName){
      var ext = fileInitalName.split('.').pop();
      fileName = request_id + "_" + name +"." + ext;
  }else{
      fileName = request_id + "_" + name + ".jpeg";
  }


  var albumPhotosKey = encodeURIComponent(albumName) + "/";

  var photoKey = albumPhotosKey + fileName;

  // Use S3 ManagedUpload class as it supports multipart uploads
  var upload = new AWS.S3.ManagedUpload({
    params: {
      Bucket: albumBucketName,
      Key: photoKey,
      Body: file,
      ACL: ""
    }
  });

  var promise = upload.promise();

  promise.then(
    function(data) {
      _callback();
      setState({
        file: URL.createObjectURL(file),
        text: "Upload a New Image",
        UploadButtonClass: "no-style-button small",
        loadingFile: false,
        ext: ext,
        fileUploaded: true,
        size: null
      })
    },
    function(err) {
      return alert("There was an error uploading your photo: ", err.message);
    }
  );

}

export const S3UploadSnap = (id, setState, imageUri, request_id, _callback) => {

  var albumBucketName = "dcamsv3";
  var bucketRegion = "us-east-2";
  var IdentityPoolId = "us-east-2:d6381cdb-1612-4dd1-ad4a-c8b28de1bd8b";
  var albumName = "upload";

  AWS.config.update({
    region: bucketRegion,
    credentials: new AWS.CognitoIdentityCredentials({
      IdentityPoolId: IdentityPoolId
    })
  });

  var name = "dl_" + id;
  var fileName = request_id + "_" + name + ".jpeg";
  var file_blob = dataURItoBlob(imageUri);

  var albumPhotosKey = encodeURIComponent(albumName) + "/";

  var photoKey = albumPhotosKey + fileName;

  // Use S3 ManagedUpload class as it supports multipart uploads
  var upload = new AWS.S3.ManagedUpload({
    params: {
      Bucket: albumBucketName,
      Key: photoKey,
      Body: file_blob,
      ACL: ""
    }
  });

  var promise = upload.promise();

  promise.then(
    function(data) {
      _callback();
      setState({
        file: file_blob,
        text: "Upload a New Image",
        UploadButtonClass: "no-style-button small",
        loadingFile: false,
        fileUploaded: true,
        size: null
      })
    },
    function(err) {
      return alert("There was an error uploading your photo: ", err.message);
    }
  );

}

export const cameraGranted = (setState, _callback) => {

  if ( navigator.permissions && navigator.permissions.query) {




  navigator.permissions.query({ name: "camera" }).then(res => {
    _callback();
    setState({
      isCamera: (res.state === "granted" ? true : false),
      isChecking: false
    })
  });
}else if (navigator.mediaDevices){
  navigator.mediaDevices.enumerateDevices().then(res => {
    res.forEach((entry) => {
    if(entry.label === ''){
      _callback();
      setState({
        isCamera: false,
        isChecking: false
      })
    }else{
      _callback();
      setState({
        isCamera: true,
        isChecking: false
      })
    }
  });
});
}else{
  _callback();
  setState({
    isCamera: true,
    isChecking: false
  })
}

}

export const dataURItoBlob = (dataURI) => {
  var binary = atob(dataURI.split(',')[1]);
  var array = [];
  for(var i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
  }
  return new Blob([new Uint8Array(array)], {type: 'image/jpeg'});
}

export const styleUpdate = (settings) => {


  let root = document.documentElement;
  let style;

  if(settings.style[0].settings !== false){

  style = settings.style[0].settings;

  //primary button
  root.style.setProperty('--veratad-primary-bg', style.primary_back);
  root.style.setProperty('--veratad-primary-color', style.primary_text);

  //secondary button
  root.style.setProperty('--veratad-secondary-bg', style.secondary_back);
  root.style.setProperty('--veratad-secondary-color', style.secondary_text);

  //icons
  root.style.setProperty('--veratad-icon', style.icons);

  }

}
