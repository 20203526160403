import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as qs from 'query-string';
import 'bootstrap/dist/css/bootstrap.min.css';
import Loading from './Loading';
import Error from './Error';
import {getDataForEmail} from './Utils.js';
import * as Sentry from "@sentry/browser";

const replay = new Sentry.Replay(
  {
    // Additional SDK configuration goes in here, for example:
    maskAllText: false,
    blockAllMedia: false
    // See below for all available options
  }
);
Sentry.init({
  dsn: "https://e5bbbf0db439485e92518f5484e1baae@o4504792099258368.ingest.sentry.io/4504792101158912",
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,

  integrations: [replay],
});



class Index extends Component {

    constructor(props) {
      super(props);
      const query = qs.parse(window.location.search);
      this.state = {
        error: false,
        errorMessage: null,
        errorDescription: null,
        isLoading: true,
        settings: [],
        query: query
      };
    }

  componentDidMount() {
    
    let currentComponent = this;
    const post = this.state.query;
    if(Object.keys(post).length > 0){
      if(post.request_id){
        getDataForEmail(post.request_id, this.setState.bind(currentComponent));
      }else if(post.errorMessage){
        currentComponent.setState({isLoading: false, error: true, errorMessage: post.errorMessage, errorDescription: post.errorDescription})
      }else{
        currentComponent.setState({isLoading: true, error: false})
      }
    }else{
      currentComponent.setState({isLoading: false, error: true, errorMessage: "You Do Not Have Access", errorDescription: "You have navigated to a wrong page. Please contact customer service."})
    }

}

componentDidUpdate(){
  if(this.state.isLoading === false){
    Sentry.setUser({ email: this.state.query.email });
  }
}

  render() {
    const { error, errorMessage, errorDescription, isLoading, settings, query } = this.state;

    if(isLoading ){
      return (  <Loading settings={settings}/> );
    } else if (error){
      return ( <Error message={errorMessage} description={errorDescription}/> );
    }else {
      return ( <App data={query} settings={settings}/> );
    }
  }
}

ReactDOM.render(
  <Index />,
  document.getElementById('root')
);
