import React, { Component } from "react";
import {withRouter} from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import '../mitekSDK.css';
import Header from './components/Header.js';
import Error from './components/Error.js';
//import ErrorScreen from '../../ErrorManual';
import Dl from './components/Dl.js';
import Passport from './components/Passport.js';
import Selfie from './components/Selfie.js';
import Verify from './components/Verify.js';
import VerificationProcessing from '../../VerificationProcessing';
import {sdkWrapper} from '../sdkWrapper';
import {acceptImage, displayError} from '../Utils.js';
import {nextPath} from '../../Utils.js';
import FrontDl from '../../assets/dl_front.svg';
import BackDl from '../../assets/dl_back.svg';
import PassportPreview from '../../assets/passport.svg';
import SelfiePreview from '../../assets/selfie.svg';

import '../../App.css';

class Index extends Component {

  constructor(props) {
    super(props);
    this.state = {
      preview_front: FrontDl,
      preview_back: BackDl,
      preview_passport: PassportPreview,
      preview_selfie: SelfiePreview,
      errorCount: 0,
      imageLoading: false,
      imageLoadingFront: false,
      imageLoadedFront: false,
      imageLoadingPassport: false,
      imageLoadedPassport: false,
      imageLoadingBack: false,
      imageLoadedBack: false,
      error: false,
      errorTitle: 'Error',
      errorSubTitle: 'Something went wrong. Please try again.',
      currentScan: false,
      isSelfie: false,
      isManualUploadError: false,
    };

    this.handleResult = this.handleResult.bind(this);
    this.handleException = this.handleException.bind(this)
    this.uploadHandleClick = this.uploadHandleClick.bind(this)
    this.iWantToPassport = this.iWantToPassport.bind(this)
    this.iWantToDL = this.iWantToDL.bind(this)
    this.checkIfUpload = this.checkIfUpload.bind(this)
  }



  handleException (excpt) {

    const { settings } = this.props;

    /*
    console.log('exception', excpt);

    const post = excpt;

    fetch("https://verataddev.com/callback",
  {
      method: "POST",
      body: JSON.stringify(post)
  })
  .then(function(res){ return res.json(); })
  .then(function(data){
    console.log("done post");
  })
  .catch(function(e) {
    console.log("post error");
  })


    const isUpload = (result.response.mibiData.ImageSource.Upload === "true" ? true : false);

    if(settings.isManualCapture === false && isUpload === true){
      this.setState({isManualUploadError: true})
      return true;
    }
    */

    let current;

    if(this.state.currentScan === "passport"){
      current = "PASSPORT";
    }else{
      current = (this.state.currentScan === "front" ? "DL_FRONT" : "PDF417_BARCODE");
    }

    const manualHints = settings.text.manualHints;
    const subtitle = settings.text.manualHintsExtra.SUBTITLE;

    const warningKey = excpt.list[0].key;
    Object.entries(manualHints).map(([key, value]) => {
      if(key === warningKey){
        displayError(value, current, this.setState.bind(this), this.state.errorCount, subtitle);
      }
        return true;
    })
  };

  checkIfUpload(result, setState, _callback){

    const {  settings } = this.props;

      const post = {image: result.response.imageData};

      fetch("https://verataddev.com/uploadCheck",
    {
        method: "POST",
        body: JSON.stringify(post)
    })
    .then(function(res){ return res.json(); })
    .then(function(data){

      if(data.isUpload === true){
        console.log("this is an upload");
        setState({isManualUploadError: true})
        return true;
      }else{
        //run a callback
        console.log("this is NOT an upload");
        setState({isManualUploadError: false})
        _callback(data, settings, setState);
      }

    })
    .catch(function(e) {
      console.log("post error for upload chexk");
    })
    };

  handleResult(result){

    console.log(result);

    const { settings } = this.props;
    const { currentScan } = this.state;

    const isUpload = (result.response.mibiData.ImageSource.Upload === "true" ? true : false);

    if(settings.isManualCapture === false && isUpload === true){
      this.setState({isManualUploadError: true})
      return true;
    }else if(settings.isManualCapture === false){
      this.checkIfUpload(result, this.setState.bind(this), function(data, settings, setState) {
          console.log("we should process this image", result);

            const request_id = settings.request_id;

            acceptImage(result, setState, request_id, false, currentScan);


        });
    }else{
      const { data } = this.props;
      const manualHints = settings.text.manualHints;
      const subtitle = settings.text.manualHintsExtra.SUBTITLE;
      if(result.response.status !== "success" && this.state.errorCount < 0){

        Object.entries(manualHints).map(([key, value]) => {
          if(key === result.response.warnings.key){
            displayError(value, result.request, this.setState.bind(this), this.state.errorCount, subtitle);
          }
          return true;
        })

      }else{
        const request_id = settings.request_id;
        const international = data.international;
        acceptImage(result, this.setState.bind(this), request_id, international, this.state.currentScan);
      }
    }

  };


  startCaptureSession(subject, target){



    const isSelfie = (this.props.match.params.type === "selfie" ? true : false);
    if(isSelfie){this.setState({isSelfie: true});}

      sdkWrapper.startManual(subject)
        .then( this.handleResult )
        .catch( this.handleException )
        .finally();
    };

    uploadHandleClick(event){


    if(this.props.match.params.type === "dl"){
      this.setState({currentScan: event.currentTarget.id});
    }else if (this.props.match.params.type === "passport"){
        this.setState({currentScan: "passport"});
    }else {
        this.setState({currentScan: "selfie"});
    }

      const currentTarget = event.currentTarget.id;


      const { data, settings } = this.props;
      let international = data.international;

      if(settings.isManualCapture === false){
        international = true;
      }

      let subject;

      if(!international){

        if(this.props.match.params.type === "dl"){
        subject = (currentTarget === "front" ? 'DL_FRONT' : 'PDF417_BARCODE');
      }else if (this.props.match.params.type === "passport"){
          subject = 'PASSPORT';
        }else {
          subject = 'SELFIE';
        }
      }else{
        if(this.props.match.params.type === "dl"){
        subject = 'DL_FRONT';
        }else if (this.props.match.params.type === "passport"){
          subject = 'PASSPORT';
        }else {
          subject = 'SELFIE';
        }
      }

      this.startCaptureSession(subject, currentTarget);
    }

    verifyDisabled(type, imageLoadedFront, imageLoadedBack, imageLoadedPassport){
      let verifyDisabled;

      if(type === "dl"){
        verifyDisabled = ((imageLoadedFront && imageLoadedBack) ? false : true)
      }else if (type === "selfie"){
        verifyDisabled = ((imageLoadedFront) ? false : true)
      }else{
        verifyDisabled = ((imageLoadedPassport) ? false : true)
      }

      return verifyDisabled;
    }

    iWanttoDisabled(){
      let Disabled;

      if(this.state.imageLoadingFront || this.state.imageLoadingBack || this.state.imageLoadingPassport){
        Disabled = true;
      }else{
        Disabled = false;
      }

      return Disabled;
    }

    iWantToPassport(){
      const { history } = this.props;
      this.setState({
        preview_front: FrontDl,
        preview_back: BackDl,
        imageLoadedFront: false,
        imageLoadedBack: false,
      })
      nextPath(history, './passport');
    }

    iWantToDL(){
      const { history } = this.props;
      this.setState({
        preview_passport: PassportPreview,
        imageLoadedPassport: false,
      })
      nextPath(history, './dl');
    }

  render() {

    const { preview_front, preview_back, imageLoadingFront, imageLoadedFront, imageLoadingBack, imageLoadedBack, error, errorTitle, errorSubTitle, preview_passport, preview_selfie, isSelfie, imageLoadingPassport, imageLoadedPassport} = this.state;
    const { history, data, settings } = this.props;

    let nlText;

    if(data.language === "en" || data.language === ""){
      nlText = 'If you submit an identity document from the Netherlands, make sure that your citizen service number (BSN) is not visible in the photo.';
    }else if(data.language === "fr"){
      nlText = "Si vous soumettez une pièce d'identité des Pays-Bas, assurez-vous que votre numéro de service citoyen (BSN) n'est pas visible sur la photo.";
    }else if(data.language === "de"){
      nlText = 'Wenn Sie einen Ausweis aus den Niederlanden einreichen, achten Sie darauf, dass Ihre Bürgerservicenummer (BSN) nicht auf dem Foto zu sehen ist.';
    }else if(data.language === "nl"){
      nlText = 'Als u een identiteitsbewijs uit Nederland overlegt, zorg er dan voor dat uw burgerservicenummer (BSN) niet zichtbaar is op de foto.';
    }else{
      nlText = 'Als u een identiteitsbewijs uit Nederland overlegt, zorg er dan voor dat uw burgerservicenummer (BSN) niet zichtbaar is op de foto.';
    }

      if(settings.isDocTypeIntro === true && data.isDocumentTypeSeen !== true){
        nextPath(history, '../documentTypeIntro');
      }

    const iWanttoDisabled = this.iWanttoDisabled();
    const verifyDisabled = this.verifyDisabled(this.props.match.params.type, imageLoadedFront, imageLoadedBack, imageLoadedPassport);

    data.type = "Direct_Upload";
    data.doc_type = this.props.match.params.type;

    let textShow;
    const header_class = (error ? "text-center mb-0" : "text-center mb-4");

    if(data.country === "NL"){
      textShow = "none";
    }else{
      textShow = (error ? "none" : "inline-block");
    }

    const isManualCaptureError = this.state.isManualUploadError;
      return ( <div>
        <div className="content">

        {data.country === "NL" &&
        <p className='text-center p-1 border-danger text-white' style={{backgroundColor: 'rgba(255, 0, 0, 0.7)', fontSize:'12px'}}>{nlText}</p>
        }

        {isManualCaptureError &&
          <p className='text-center p-1 border-danger text-white' style={{backgroundColor: 'rgba(255, 0, 0, 0.7)', fontSize:'12px'}}>You are not allowed to upload directly from your photo library. Please take a live picture of your ID.</p>
        }

{this.props.match.params.type !== "verificationprocessing" &&
      <div>
          <Container>

            <Header settings={settings} header_class={header_class} textShow={textShow} type={this.props.match.params.type}/>

            {error &&
              <Error errorTitle={errorTitle} errorSubTitle={errorSubTitle}/>
            }

            {this.props.match.params.type === "dl" &&
            <Dl settings={settings} uploadHandleClick={this.uploadHandleClick} imageLoadingFront={imageLoadingFront} imageLoadingBack={imageLoadingBack} imageLoadedFront={imageLoadedFront} imageLoadedBack={imageLoadedBack} preview_front={preview_front} preview_back={preview_back}/>
          }

          {this.props.match.params.type === "passport" &&
          <Passport settings={settings} uploadHandleClick={this.uploadHandleClick} imageLoadingFront={imageLoadingPassport} imageLoadedFront={imageLoadedPassport}  preview_passport={preview_passport}/>
        }

        {this.props.match.params.type === "selfie" &&
        <Selfie settings={settings} uploadHandleClick={this.uploadHandleClick} imageLoadingFront={imageLoadingFront} imageLoadedFront={imageLoadedFront}  preview_selfie={preview_selfie}/>
      }


        <Verify verifyDisabled={verifyDisabled} iWantToPassport={this.iWantToPassport} iWantToDL={this.iWantToDL} iWanttoDisabled={iWanttoDisabled} history={history} data={data} settings={settings} type={this.props.match.params.type}/>

          </Container>
    </div>

  }

    {this.props.match.params.type === "verificationprocessing" &&
      <VerificationProcessing selfie={isSelfie}  settings={settings} data={data} message={"Verification in progress..."}/>
    }


  </div>
</div>
      );
  }
}

export default withRouter (Index);
