import React, { Component } from "react";
import {withRouter} from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {verifyPost, nextPath, back} from '../../../Utils.js';

import '../../../App.css';

class Confirmation extends Component {

  render() {

    const { image_preview, history, side, type, data, settings } = this.props;

    const selfie_from_register = settings.selfie;

    let ScanSideStartPath;

    console.log('here selfie_from_register', selfie_from_register);
    console.log('the type is ', type); 

    if(type === "dl" && side === "front"){
      ScanSideStartPath = '../scansidestart/dl/back';
    }else if(type === "dl" && side === "back" && selfie_from_register){
      ScanSideStartPath = '../../selfie';
    }else if(selfie_from_register && type === "passport"){
      ScanSideStartPath = '../../selfie';
    }else{
      ScanSideStartPath = 'verificationprocessing';
    }

    data.doc_type = (type === "dl" ? "dl" : "passport");
    data.type = 'SDK_Scan';

      return ( <div>

        <Row className="text-left mb-0 pb-0">
          <Col>
            <h3 className="font-weight-bold">{settings.text.confirmation.title}</h3>
            <p>{settings.text.confirmation.subTitle}</p>
          </Col>
        </Row>

        <Row className="mt-2">
          <Col className="text-center">
            <img src={image_preview} alt="ID Preview" height={300}/>
          </Col>
        </Row>

        {ScanSideStartPath === "verificationprocessing" &&

        <Row className="mt-5">
          <Col sm={12} md={6}>

            <div>
            <button
              type="button"
              className="veratad-primary mb-4"
              onClick={() => verifyPost(data, settings, history) }
              >
            {settings.text.confirmation.actionButtonVerify}
            </button>
            </div>


          </Col>
          <Col sm={12} md={6}>
              <button
                type="button"
                value="device"
                className="veratad-secondary mb-4"
                onClick={() => back(history) }
              >
              {settings.text.confirmation.secondaryButton}
              </button>
          </Col>
        </Row>

        }

        {ScanSideStartPath !== "verificationprocessing" &&

        <Row className="mt-5">
          <Col sm={12} md={6}>
            <button
              type="button"
              className="veratad-primary mb-4"
              onClick={() => nextPath(history, ScanSideStartPath) }
              //onClick={startCaptureSession}
            >
            {settings.text.confirmation.actionButton}
            </button>
          </Col>
          <Col sm={12} md={6}>
              <button
                type="button"
                value="device"
                className="veratad-secondary mb-4"
                onClick={() => back(history) }
              >
              {settings.text.confirmation.secondaryButton}
              </button>
          </Col>
        </Row>
      }

    </div>
  );

  }
}

export default withRouter (Confirmation);
