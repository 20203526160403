import React, { Component } from "react";
import {withRouter} from 'react-router-dom';
import {nextPath} from '../../Utils.js'
import Container from 'react-bootstrap/Container';
import '../mitekSDK.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Svg from '../../Svg.js';

import FrontDl from '../../assets/dl_front.svg';
import BackDl from '../../assets/dl_back.svg';
import Passport from '../../assets/passport.svg';

import '../../App.css';


class ScanSideStart extends Component {

  constructor(props) {
    super(props);
    this.state = {
      secondsLeft: 10,
    };
  }

  check() {
    const secondsLeft = this.state.secondsLeft - 1;

    this.setState({secondsLeft: secondsLeft});
    if(secondsLeft <= 0){
      const { history } = this.props;
      const theNextPath = this.getNextPath();
      nextPath(history, theNextPath);
    }
  }

  componentDidMount() {
    this.interval = setInterval(() => this.check(), 1000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  getNextPath(){

    let nextPath
    if(this.props.match.params.type === "dl"){
        nextPath = (this.props.match.params.side === "front" ? `${process.env.PUBLIC_URL}/snap/scan/dl/front` : `${process.env.PUBLIC_URL}/snap/scan/dl/back`);
    }else{
        nextPath = `${process.env.PUBLIC_URL}/snap/scan/passport/front`;
    }
    return nextPath;
  }

  render() {

    const { secondsLeft } = this.state;
    const { history, settings } = this.props;

    const icon_color = settings.style[0].settings.icons;

    const forceNextPath = this.getNextPath();

    let bigMessage;
    let imgSrc;
    if(this.props.match.params.type === "dl"){
        bigMessage = (this.props.match.params.side === "front" ? settings.text.scanStart.dlFront_bigMessage : settings.text.scanStart.dlBack_bigMessage);
        imgSrc = (this.props.match.params.side === "front" ? FrontDl : BackDl);
    }else{
        bigMessage = settings.text.scanStart.passport_bigMessage;
        imgSrc = Passport;
    }

    return (

    <div className="content"
    onClick={() => nextPath(history, forceNextPath) }
    >
      <Container className="h-100 mt-5">

        <Row className="text-center h-100 justify-content-center align-items-center">
          <Col>

          <Row className="text-left ">
            <Col>
              <Svg src={imgSrc} color={icon_color} width={150} height={150} />
            </Col>
          </Row>

          <Row className="text-left mt-5">
            <Col>
              <h2 className="font-weight-bold">{bigMessage}</h2>
            </Col>
          </Row>


      <Row className="text-left mt-4 mb-3">
        <Col>
          <p className="mb-0">{settings.text.scanStart.explanation_1} <span className="font-weight-bold h2">{secondsLeft} </span> </p>
          <p className="mt-1 pt-0">{settings.text.scanStart.explanation_2}</p>
        </Col>
      </Row>

      </Col>
    </Row>

      </Container>
    </div>

    );
  }
}

export default withRouter (ScanSideStart);
